define("discourse/plugins/discourse-radiant-member/discourse/components/radiant-info", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function splitGroup(item) {
    const x = item.split(":");
    return {
      group: x[0],
      required: x[1]
    };
  }
  var _default = _exports.default = _component.default.extend(dt7948.p({
    groupStatus(values) {
      let required = values.split("|").map(splitGroup);
      return required;
    }
  }, [["method", "groupStatus", [(0, _decorators.default)("siteSettings.radiant_group_values")]]]));
});